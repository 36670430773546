body {
  background-color: #eee;
  margin: 8px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input:disabled {
  color: black;
}

.MuiDataGrid-mainGridContainer {
  color: transparent !important;
}

.MuiDataGrid-columnsContainer,
.MuiDataGrid-window {
  color: rgba(0, 0, 0, 0.87);
}

.MuiDataGrid-root .MuiDataGrid-window {
  overflow-x: hidden !important;
}
