.sort__row__one {
  display: grid;
  grid-column-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem auto;
}

.sort__row__one:first-of-type {
  margin-top: 0.5rem;
}

.datepickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.sort__row__two {
  display: grid;
  grid-column-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem auto;
}

.sort__row__three {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem auto;
}
