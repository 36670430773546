.main {
  min-width: 1150px;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}

.main__table {
  min-height: 400px;
  text-align: right;
}

.sort {
  min-width: 1150px;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}

.sort__table {
  min-height: 400px;
  text-align: right;
}

.clients {
  min-width: 1150px;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}

.clients__table {
  min-height: 400px;
  text-align: right;
  margin-top: 1rem;
}

.debtors {
  min-width: 1150px;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
}

.debtors__table {
  min-height: 400px;
  text-align: right;
  margin-top: 1rem;
}
