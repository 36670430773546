.header__container .MuiToolbar-root {
  display: grid !important;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 0 1rem;
  min-height: 50px;
}

.menu {
  background-color: rgba(0, 60, 170, 0.95);
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
