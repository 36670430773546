* {
  outline: none !important;
}

/*               Remove watermark               */
.MuiDataGrid-mainGridContainer > div:first-child {
  display: none;
}

.MuiDataGrid-columnsContainer {
  font-size: 13px;
}

.MuiDataGrid-window {
  font-size: 12px;
}

.MuiDataGrid-sortIcon {
  font-size: 13px !important;
}

.MuiDataGrid-colCellTitleContainer {
  justify-content: center !important;
}

.MuiDataGrid-cell {
  padding: 0px 8px !important;
}

.MuiDataGrid-cell img {
  cursor: pointer;
  vertical-align: middle;
}

.MuiDataGrid-colCell {
  padding: 0px 10px !important;
}

.MuiDataGrid-cellRight {
  text-align: right;
  justify-content: flex-end;
}

.MuiDataGrid-root {
  border: none !important;
}

.x-grid {
  height: 100%;
  width: 100%;
  text-align: left;
  background-color: white;
  border-radius: 4px !important;
  border-top-right-radius: 0 !important;
}

.action__bar__stat {
  top: 4.6rem;
  position: absolute;
  margin: auto 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 1.1rem;
}

.sort__info {
  width: 60%;
  text-align: center;
  top: 4.7rem;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%, 0%);
}

.sort__info label {
  margin: auto 1rem;
}

.centered {
  justify-content: center;
}

.frame {
  display: flex;
  height: 100%;
  width: 100%;
}

.grid-holder {
  flex-grow: 1;
}

.main__table .MuiFormControl-root {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
}

.main__table .MuiOutlinedInput-inputMarginDense {
  padding: 8px;
}

.main__table .MuiOutlinedInput-notchedOutline {
  border: none;
}

.main__table .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(8px, 0px) scale(0.75) !important;
}

.main__table .MuiOutlinedInput-adornedEnd {
  padding-right: 0px;
}

.main__table .export-btn {
  color: rgba(0, 0, 0, 0.3);
  font-variant: traditional;
  text-transform: none;
  border: none;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
}

.main__table .MuiButtonBase-root:hover {
  color: rgba(0, 0, 0, 0.7);
  transition: color linear 0.2s;
}

.sort__table .MuiFormControl-root {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
}

.sort__table .MuiOutlinedInput-inputMarginDense {
  padding: 8px;
}

.sort__table .MuiOutlinedInput-notchedOutline {
  border: none;
}

.sort__table .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(8px, 0px) scale(0.75) !important;
}

.sort__table .MuiOutlinedInput-adornedEnd {
  padding-right: 0px;
}

.sort__table .export-btn {
  color: rgba(0, 0, 0, 0.3);
  font-variant: traditional;
  text-transform: none;
  border: none;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
}

.sort__table .MuiButtonBase-root:hover {
  color: rgba(0, 0, 0, 0.7);
  transition: color linear 0.2s;
}

@media (max-width: 1300px) {
  .MuiDataGrid-columnsContainer {
    font-size: 10px;
  }
  .MuiDataGrid-window {
    font-size: 10px;
  }
}
