.action_bar_container {
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: white;
}

.MuiCollapse-container {
  will-change: height;
}

.clients_action_bar_container {
  margin-bottom: 1rem;
}

.action__bar {
  background-color: white;
  display: grid;
  min-height: 47px;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.action__bar__stat {
  margin: auto 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-size: 1.1rem;
}

.action__bar__button {
  display: flex;
  grid-column: 3;
  justify-content: flex-end;
  margin: 0.5rem;
  white-space: nowrap;
}

.action__bar__info {
  grid-column: 2;
  align-self: center;
  justify-self: center;
}

.debtor-mark {
  vertical-align: sub;
  margin-bottom: -0.05rem;
}

@media (max-width: 1300px) {
  .action__bar__stat {
    font-size: 12px !important;
  }

  .debtor-mark {
    vertical-align: bottom;
  }
}
