/* .MuiDataGrid-viewport {
  max-width: 95% !important;
} */
.customers-grid .MuiDataGrid-cell img {
  cursor: default;
}

.name-link {
  color: black !important;
  cursor: pointer;
}

.clients__table .MuiFormControl-root {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
}

.clients__table .MuiOutlinedInput-inputMarginDense {
  padding: 8px;
}

.clients__table .MuiOutlinedInput-notchedOutline {
  border: none;
}

.clients__table .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(8px, 0px) scale(0.75) !important;
}

.clients__table .MuiOutlinedInput-adornedEnd {
  padding-right: 0px;
}

.clients__table .export-btn {
  color: rgba(0, 0, 0, 0.3);
  font-variant: traditional;
  text-transform: none;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.clients__table .MuiButtonBase-root:hover {
  color: rgba(0, 0, 0, 0.7);
  transition: color linear 0.2s;
}
