.blank-page {
  text-align: center;
  padding: 1rem;
}
.blue-holder {
  margin-top: 1rem;
  width: 18rem;
  height: 19.3rem;
  background-color: rgba(0, 60, 170, 0.95) !important;
  padding: 14rem;
}

.blue-holder h2 {
  line-height: 9rem !important;
}

.info {
  width: 600px;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: 14rem;
}

.MuiBox-root-2 {
  padding: 1rem;
  margin: 0 !important;
}
