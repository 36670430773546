.container {
  margin: 0 auto;
  width: 55%;
}

.row__one {
  display: grid;
  grid-column-gap: 10px;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 0.05fr 3fr 3fr 3fr;
  margin: 1rem auto;
}

.row__one:first-of-type {
  margin-top: 0.5rem;
}
.row__two {
  display: grid;
  grid-column-gap: 15px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 0.05fr 1fr 1fr 1fr 1fr 0.05fr 1fr 1fr;
  margin: 1rem auto;
}

.row__two .MuiOutlinedInput-input {
  padding: 8px;
}

.row__two .MuiOutlinedInput-inputMarginDense {
  padding: 8px 8px;
}

.row__three .MuiOutlinedInput-inputMarginDense {
  padding: 8px 8px;
}

.row__three {
  display: grid;
  grid-column-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 1rem auto;
}

.row__four {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 1rem auto;
}

.row__four > * {
  height: 1rem;
  font-size: 0.5rem !important;
  margin-right: 0 !important;
}

.row__five {
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  grid-column-gap: 10px;
  margin: 1rem auto;
}

.controls {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.save__button {
  width: 100%;
  grid-column: 2;
}

.MuiCollapse-wrapperInner {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
}

.buttons {
  display: flex;
  width: 19rem;
  justify-content: space-between;
}

#outlined {
  padding: 8px;
}

@media (max-width: 1250px) {
  .controls .MuiSwitch-root {
    width: 48px;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(10px) !important;
  }
}
